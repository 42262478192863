import React from "react";
import "./test.css";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import InputUrl from "../components/InputUrl";
import Features from "../components/Features";
import Poc from "../components/Poc";
import ShowResult from "../components/ShowResult";
import Loader from "../components/Loader";
import SharePoc from "../components/SharePoc";
import FinalResult from "../components/FinalResult";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const Test = () => {
  const navigate = useNavigate();
  const [searchParams1] = useSearchParams();
  const searchParams = searchParams1.get("url");
  const [url, setUrl] = useState();
  const [progress, setProgress] = useState(true);
  const [navigationOccured, setNavigationOccured] = useState();
  const [emitIP, setEmitIP] = useState();
  const [emitDate, setEmitDate] = useState();
  const [emitIsRedirection, setEmitIsRedirection] = useState();
  const [emitRedirectedUrl, setEmitRedirectedUrl] = useState();
  const [emiturl, setEmiturl] = useState();
  const [headers, setHeaders] = useState();
  const [received, setreceived] = useState(false);
  const [allresponseofgetapi, setAllresponseofgetapi] = useState();
  const [showHeadersComponent, setshowHeadersComponent] = useState(true);
  const [isCheckedPoc, setisCheckedPoc] = useState(false);
  const [emitCouldNotConnect, setemitCouldNotConnect] = useState(false);
  const [isvulnerableornot, setisvulnerableornot] = useState(false);
  const setisvulnerableornotFn = (data) => {
    setisvulnerableornot(data)
  }
  const crossCommunication = (data) => {
    setisCheckedPoc(data);
  };

  useEffect(() => {
    if (searchParams) {
      if (searchParams.includes("https:")) {
        window.location.replace(`${process.env.REACT_APP_httpsSiteUrl}/test?url=${searchParams}`);
      } else {
        document.getElementById("thebox")?.focus();
        setProgress(true);
        localStorage.setItem("response-received", "false");
        localStorage.setItem("start-time", String(Date.now()));
        let newUrl;
        if (!searchParams.startsWith("http://")) {
          newUrl = "http://" + searchParams;
        } else {
          newUrl = searchParams;
        }
        const url =
          process.env.REACT_APP_headersUrl + "/" + encodeURIComponent(newUrl);
        fetch(url, {
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((result) => {
            return result.json();
          })
          .then((data) => {
            localStorage.setItem("response-received", "true");
            const datareceived = data;
            setProgress(false);
            if (datareceived["success"]) {
              setProgress(false);
              localStorage.setItem("URL", data["newurl"]);
              setNavigationOccured(data["newurl"]);
              var id = datareceived["_id"];
              setEmitIP(datareceived["IPAddress"]);
              setEmitDate(datareceived["date"]);
              setEmitIsRedirection(datareceived["redirection"]);
              setEmitRedirectedUrl(datareceived["redirectedTo"]);
              setreceived(true);
              let headers;
              headers = datareceived["ResponseHeaders"];
              let urlForRequest1 =
                '{"url"' + ':"' + datareceived["newurl"] + '"}';
              setEmiturl(urlForRequest1);
              localStorage.setItem(
                "HeadersInformation",
                JSON.stringify(headers)
              );
              setHeaders(headers);
              setAllresponseofgetapi(data);
            } else {
              setAllresponseofgetapi(data);
              setEmitIsRedirection(0);
              let urlForRequest1 = '{"url"' + ':"' + datareceived["newurl"] + '"}';
              setEmiturl(urlForRequest1);
              setemitCouldNotConnect(true);
              setEmitDate(String(new Date()));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      navigate.push("/");
    }
  }, [searchParams, navigate]);

  useEffect(() => {
    if (localStorage.getItem("first-scan-date") !== null) {
      let new_d = new Date();
      if (new_d.toDateString() !== localStorage.getItem("first-scan-date")) {
        localStorage.setItem("first-scan-date", new_d.toDateString());
      }
    } else {
      let d = new Date();
      localStorage.setItem("first-scan-date", d.toDateString());
    }
    setTimeout(() => {
      if (localStorage.getItem("response-received") == "false")
        alert(
          "The target site took too long to respond and the connection timed out. Try again later."
        );
    }, 10000);
  }, []);
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
      <Header />
      <div className="page-wrap2" onClick={closeNav}>
        <div id="newsnackbar">
          Our server could not reach to the site. If it is Intranet...Hurray !
          We have got that iframed for you.
        </div>
        <div className="row">
          <h4 className="title">
            <strong>Test for Clickjacking now</strong>
          </h4>
          <InputUrl />
          {navigationOccured && (
            <SharePoc navigationOccured={navigationOccured} />
          )}
        </div>
        {progress ? (
          <Loader />
        ) : (
          <div>
            <div className="flex my-view">
              <div style={{ flexBasis: "60%" }}>
                <Poc emiturl={emiturl} isCheckedPoc={isCheckedPoc} />
              </div>
              <div style={{ flexBasis: "30%", padding: "13px 15px" }}>
                <ShowResult
                  ResultsArrived={received}
                  emitIsRedirection={emitIsRedirection}
                  emitRedirectedUrl={emitRedirectedUrl}
                  emiturl={emiturl}
                  headers={headers}
                  IPAddress={emitIP}
                  time={emitDate}
                  crossCommunication={crossCommunication}
                  setisvulnerableornotFn={setisvulnerableornotFn}
               />
              </div>
            </div>
            <div className="center-align">
              <FinalResult data={isvulnerableornot} resultsArrived={headers} emitCouldNotConnect={emitCouldNotConnect}/>
            </div>
          </div>
        )}
        <Features />
      </div>
      </DndProvider>
    </div>
  );
};

export default Test;

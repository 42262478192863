import React, { useEffect } from 'react'
import './features.css'
// import Link from 'next/link';
import { Link } from 'react-router-dom';
const Features = () => {
    useEffect(()=>{
        let ob = document.getElementsByClassName('one-box');
        let svgs = document.getElementsByTagName('svg');
        [...ob].map(z => {
        z.addEventListener('mouseover', () => {
            let classOfSVG = z.className.split(' ')[1];
            if(classOfSVG === 'poc-box'){
            document.getElementById('poc-svg').classList.remove('on-svg-leave');
            document.getElementById('poc-svg').classList.add('on-svg-hover');
            }
            if(classOfSVG === 'fix-box'){
            document.getElementById('fix-svg').classList.remove('on-svg-leave');
            document.getElementById('fix-svg').classList.add('on-svg-hover');
            }
            if(classOfSVG === 'tutorial-box'){
            document.getElementById('tutorial-svg').classList.remove('on-svg-leave');
            document.getElementById('tutorial-svg').classList.add('on-svg-hover');
            }

        })

        z.addEventListener('mouseout', () => {
            let classOfSVG = z.className.split(' ')[1];
            if(classOfSVG === 'poc-box'){
            document.getElementById('poc-svg').classList.remove('on-svg-hover');
            document.getElementById('poc-svg').classList.add('on-svg-leave');
            }
            if(classOfSVG === 'fix-box'){
            document.getElementById('fix-svg').classList.remove('on-svg-hover');
            document.getElementById('fix-svg').classList.add('on-svg-leave');
            }
            if(classOfSVG === 'tutorial-box'){
            document.getElementById('tutorial-svg').classList.remove('on-svg-hover');
            document.getElementById('tutorial-svg').classList.add('on-svg-leave');
            }

        })

        })
    },[])
   
  return (
    <>
      <div className="features-info-container">
        <h2 className="page-heading-ft"><strong>Need help with understanding, making poc and fixing Clickjacking?</strong></h2>
        <div className="inner-features-container">
          <Link to={`${process.env.REACT_APP_makePocURL}`}>
            <div className="one-box poc-box">
              <div className="box-svg">
                <svg id="poc-svg" width={29} height={26} viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M2 1.69995H12C12.1657 1.69995 12.3 1.83435 12.3 2V12C12.3 12.1656 12.1657 12.3 12 12.3H2C1.83432 12.3 1.7 12.1656 1.7 12V2C1.7 1.83435 1.83432 1.69995 2 1.69995ZM0 2C0 0.895386 0.895432 0 2 0H12C13.1046 0 14 0.895386 14 2V12C14 13.1046 13.1046 14 12 14H2C0.895432 14 0 13.1046 0 12V2ZM0.850006 18C0.380554 18 0 18.3806 0 18.85C0 19.3195 0.380554 19.7 0.850006 19.7H28.15C28.6194 19.7 29 19.3195 29 18.85C29 18.3806 28.6194 18 28.15 18H0.850006ZM17 12.85C17 12.3806 17.3806 12 17.85 12H28.15C28.6194 12 29 12.3806 29 12.85C29 13.3195 28.6194 13.7 28.15 13.7H17.85C17.3806 13.7 17 13.3195 17 12.85ZM17.85 7C17.3806 7 17 7.38062 17 7.84998C17 8.31946 17.3806 8.69995 17.85 8.69995H28.15C28.6194 8.69995 29 8.31946 29 7.84998C29 7.38062 28.6194 7 28.15 7H17.85ZM17 2.84998C17 2.38062 17.3806 2 17.85 2H28.15C28.6194 2 29 2.38062 29 2.84998C29 3.31946 28.6194 3.69995 28.15 3.69995H17.85C17.3806 3.69995 17 3.31946 17 2.84998ZM0.850006 24C0.380554 24 0 24.3806 0 24.85C0 25.3195 0.380554 25.7 0.850006 25.7H19.15C19.6194 25.7 20 25.3195 20 24.85C20 24.3806 19.6194 24 19.15 24H0.850006Z" fill="#F7567C" />
                </svg>
                <h6 className="box-svg-title">Make Quick PoC</h6>
                <p className="box-svg-text">Use readymade text and code for vulnerability description, mitigation to show to stakeholders/clients</p>
              </div>
            </div>
          </Link>
          <Link to={`${process.env.REACT_APP_fixVulnURL}`}>
            <div className="one-box fix-box">
              <div className="box-svg">
                <svg id="fix-svg" width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0)">
                    <path d="M29.952 12.6014C29.538 13.0133 24.4757 12.5539 23.9443 12.0247C23.4158 11.4948 22.9493 6.58077 23.4778 6.05013C24.007 5.52165 28.2701 1.25997 28.2701 1.25997C28.2701 1.25997 28.7777 0.691174 28.0454 0.689014C25.6874 0.680374 20.8937 0.733654 18.5724 3.05709L18.0936 3.53373C15.0682 6.57861 15.1502 11.3277 18.1238 14.3172L21.726 17.9186C24.7032 20.9059 29.4386 20.9916 32.4756 17.9496L32.9515 17.4729C35.2015 15.2244 35.2706 10.4709 35.28 8.07045C35.2865 7.30509 34.7263 7.79901 34.7263 7.79901C34.7263 7.79901 30.3638 12.1874 29.952 12.6014Z" stroke="#F7567C" strokeWidth="1.902" strokeMiterlimit={10} />
                    <path d="M17.491 13.3257L2.16576 27.3456C1.0872 28.4256 -0.334083 31.3617 2.16576 33.8623C4.66632 36.3629 7.60248 34.9409 8.6796 33.8623C8.6796 33.8623 22.2228 18.4925 22.5864 18.6667" stroke="#F7567C" strokeWidth="1.799" strokeMiterlimit={10} />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width={36} height={36} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h6 className="box-svg-title">Fixing the vulnerability</h6>
                <p className="box-svg-text">Get to know the code, techniques to fix clickjacking vlnerability</p>
              </div>
            </div>
          </Link>
          <Link to={`${process.env.REACT_APP_interactiveTutorialUrl}`}>
            <div className="one-box tutorial-box">
              <div className="box-svg">
                <svg id="tutorial-svg" width={44} height={48} viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="12.85" y="33.85" width="18.3" height="6.3" stroke="#F7567C" strokeWidth="1.7" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M20 0H23V2H44V34H0V2H20V0ZM42.3 3.69995H1.7V32.3H42.3V3.69995ZM6 5H10V9H6V5ZM6 12H10V16H6V12ZM10 19H6V23H10V19ZM6 26H10V30H6V26Z" fill="#F7567C" />
                  <path d="M13.7 41.7H30.3V46.3H28V48H34V46.3H32V40H12V46.3H10V48H16V46.3H13.7V41.7Z" fill="#F7567C" />
                </svg>
                <h6 className="box-svg-title">Interactive Tutorial</h6>
                <p className="box-svg-text">Walk through in a step by step interactve way to exploit clickjacking vulnerability</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Features

import React, { useEffect } from 'react'
import './Poc.css'
import update from "immutability-helper";
import { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { Box } from './Box';
const Poc = ({emiturl, isCheckedPoc}) => {

  const [urlToLoadInIFrame, setUrlToLoadInIFrame] = useState('about:blank');
  useEffect(()=>{
    if(emiturl){
      setUrlToLoadInIFrame(JSON.parse(emiturl).url)
    }
  },[emiturl])
  const [boxes, setBoxes] = useState({
    a: { top: 20, left: 80 }
  });
  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top }
          }
        })
      );
    },
    [boxes, setBoxes]
  );
  const [, drop] = useDrop(
    () => ({
      accept: "box",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      }
    }),
    [moveBox]
  );
  return (<>
      <div style={{position: 'relative'}}>
        <iframe src={urlToLoadInIFrame} id="iframe" />
        {isCheckedPoc && 
          <div ref={drop} className="clickhere">
            <Box key={"a"} id={"a"} left={boxes.a.left} top={boxes.a.top}>
              <img src="/images/clickheretransparent.png" />
            </Box>
          </div>
        }
      </div>
      </>
  )
}

export default Poc

import React, { useEffect, useState } from "react";
import './FinalResult.css'
const FinalResult = ({ data, resultsArrived, emitCouldNotConnect=false }) => {
  const [vulnerableornot, setvulnerableornot] = useState(false);
  const [noConnection, setnoConnection] = useState(false);
  useEffect(() => {
    setvulnerableornot(data);
  }, [data]);
  useEffect(()=>{
    setnoConnection(emitCouldNotConnect)
  },[emitCouldNotConnect])
  return (
    <div>
      {resultsArrived && (
        <div style={{textAlign: 'center', marginTop: '30px'}}>
          {vulnerableornot ? (
            <div className="center-align">
              <div className="chip" id="notsafe">
                <img src="/images/notsafe.png" alt="Vulnerable to iframing" />
                It is vulnerable to clickjacking attack.
              </div>
            </div>
          ) : (
            <div className="center-align">
              <div className="chip" id="safe">
                <img src="/images/safe.png" alt="Secure from iframing" />
                It is safe from clickjacking attack.
              </div>
            </div>
          )}
        </div>
      )}
      {noConnection && <div className="noConnectionMSG">
        <span className="wrong"></span><span className="issuemsg"></span>
        <span className="intranetmsg">If the URL is <span className="intranetstress">Intranet, then Hurray !!</span> We've got it covered in the iFrame.</span>
      </div>}
    </div>
  );
};

export default FinalResult;

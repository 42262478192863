import React from 'react'
import { useState } from 'react'
import './Home.css'
import Header from '../components/Header'
import InputUrl from '../components/InputUrl'
import Poc from '../components/Poc'
import ShowResult from '../components/ShowResult'
import Features from '../components/Features'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const Main = () => {
    const [isCheckedPoc, setisCheckedPoc] = useState(false);

    const crossCommunication = (data) => {
        setisCheckedPoc(data)
    }
    function closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    }
  return (
    <>
      <DndProvider backend={HTML5Backend}>
      <Header/>
      <div className="page-wrap" onClick={closeNav}>
          <br />
          <h4 className="title">
            <strong>Test for Clickjacking now</strong>
          </h4>
          <InputUrl />
          <div className="flex my-view">
            <div style={{ flexBasis: "60%" }}>
              <Poc isCheckedPoc={isCheckedPoc}/>
            </div>
            <div style={{ flexBasis: "30%", padding: "13px 15px"}}>
              <ShowResult crossCommunication={crossCommunication}/>
            </div>
          </div>
          <Features />
        </div>
        </DndProvider>
    </>
  )
}

export default Main

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import './Header.css'
const Header = () => {
  const router = useLocation();
  const [moto, setMoto] = useState("");
  const [showObj, setshowObj] = useState({
    showHome: true,
    showDonate: true,
    showAbout: true,
  });

  useEffect(() => {
    let moto;
    let showObj = {
      showHome: true,
      showDonate: true,
      showAbout: true,
    };
    let addressBar = window.location;
    if (
      addressBar
        .toString()
        .includes(process.env.REACT_APP_fixVulnURL.split("/")[3])
    ) {
      moto = "Get techniques to fix it";
      showObj["showHome"] = true;
      showObj["showAbout"] = true;
      showObj["showDonate"] = true;
    } else if (
      addressBar
        .toString()
        .includes(process.env.REACT_APP_interactiveTutorialUrl.split("/")[3])
    ) {
      moto = "Interact and understand it";
      showObj["showHome"] = true;
      showObj["showAbout"] = true;
      showObj["showDonate"] = true;
    } else if (
      addressBar
        .toString()
        .includes(process.env.REACT_APP_makePocURL.split("/")[3])
    ) {
      moto = "Assist in making PoC";
      showObj["showHome"] = true;
      showObj["showAbout"] = true;
      showObj["showDonate"] = true;
    } else if (
      addressBar
        .toString()
        .includes(process.env.REACT_APP_aboutURL.split("/")[3])
    ) {
      moto = "About us";
      showObj["showHome"] = true;
      showObj["showAbout"] = false;
      showObj["showDonate"] = true;
    } else if (
      addressBar
        .toString()
        .includes(process.env.REACT_APP_donateURL.split("/")[3])
    ) {
      moto = "Support us";
      showObj["showHome"] = true;
      showObj["showAbout"] = true;
      showObj["showDonate"] = false;
    } else {
      moto = "Test both Internet and Intranet sites";
      showObj["showHome"] = false;
      showObj["showAbout"] = true;
      showObj["showDonate"] = true;
    }
    setMoto(moto);
    setshowObj(showObj);
  }, [router]);
  function openNav() {
    document.getElementById("mySidenav").style.width = "300px";
  }
  
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  return (
    <>
    <i className="material-icons ham-burger" onClick={openNav}>menu</i>
    <div className="myNav" onClick={closeNav}>
      <div className="navbar-fixed">
        <nav className="nav-wrapper">
          <div className="container nav-container flex justify-content-between">
            <div className="site-moto">{moto}</div>
            <Link to={`${process.env.REACT_APP_httpsSiteUrl}`} className="brand-logo flex">
              <img
                className="left"
                id="image"
                src="/images/logos/BuiltTransparent2.png"
                alt="Brand Logo"
                width="64"
                height="64"
              />
              <strong>&nbsp;Clickjacker</strong>
            </Link>
            <ul className="right hide-on-med-and-down flex">
              <li style={{padding : '0 15px'}}>
                <Link to="/home">HOME</Link>
              </li>
              <li style={{padding : '0 15px'}}>
                <Link to={`${process.env.REACT_APP_aboutURL}`}>ABOUT</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div id="mySidenav" className="sidenav">
    {showObj["showHome"] && (
      <>
      <br />
      <br />
      <br />
        <Link href="/home">HOME</Link>
        </>
    )}
    
    {showObj["showAbout"] && (
      <>
      <br />
      <br />
      <br />
        <Link href="/about">ABOUT</Link>
      </>
    )}
    </div>
    </>
  );
};

export default Header;

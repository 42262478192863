import React, { useEffect, useState } from "react";
import { MdOutlineContentCopy } from 'react-icons/md';

const SharePoc = ({ navigationOccured }) => {
  const [showsharepoc, setshowsharepoc] = useState();
  const [shareablepoc, setshareablepoc] = useState();
  const onShareButtonClick = () => {
    navigator.clipboard.writeText(shareablepoc);
    let x = document.getElementById("snackbar");
    x.className = "show";
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  const sendNewUserRequest = () => {
    let data;
    if(localStorage.getItem("new-user") == "false"){
      data = { "new-user": false };
    } else {
      data = { "new-user": true };
    }
    // this.primaryAnalytics.sendAnalytics(JSON.stringify(data));
  }
  useEffect(() => {
    setshowsharepoc(true);
    setshareablepoc(window.location.origin + "/test?url=" + navigationOccured);
    let getUserFromLocal = localStorage.getItem("new-user");
    if (getUserFromLocal != "Saurabh") {
      if (getUserFromLocal == undefined) {
        localStorage.setItem("new-user", "true");
      } else {
        localStorage.setItem("new-user", "false");
      }
    //   sendNewUserRequest();
    }
  }, []);
  
  return (
    <>
      {showsharepoc && (
        <div style={{textAlign: 'center', marginBottom: '30px'}}>
        <span id="sharepoc">
          <span>Share result via url: </span>
          <a
            href={shareablepoc}
            target="_blank"
            className="collection hide-on-small-only"
            style={{color: '#039be5'}}
          >
            {shareablepoc}
          </a>
          <a
            id="clipboard-btn"
            className="waves-effect waves-teal btn-flat ml-2"
            onClick={onShareButtonClick}
            style={{marginTop: '-4px', backgroundColor: 'transparent'}}
          >
            <i className="material-icons" >content_copy</i>
            COPY
          </a>

          <div id="snackbar" className="container-copy">
            <p>Link Copied To Clipboard</p>
          </div>
          </span>
        </div>
      )}
    </>
  );
};

export default SharePoc;

import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./about.css";

const InputUrl = () => {
  const navigate = useNavigate();
  const [searchParams1] = useSearchParams();
  const searchParams = searchParams1.get("url");
  const [firstOption, setFirstOption] = useState("http://");
  const [secondOption, setSecondOption] = useState("https://");
  const firstProtocol = () => {};
  const secondProtocol = () => {
    setFirstOption(secondOption);
    setSecondOption(firstOption);
    let x = document.getElementsByClassName("dropdown-inner")[0];
    let y = document.getElementsByClassName("dropdown-wrapper")[0];
    x.classList.add("after-drop-down-selection");
    y.classList.add("after-drop-down-selection");
    setTimeout(() => {
      x.classList.remove("after-drop-down-selection");
      y.classList.remove("after-drop-down-selection");
    }, 100);
  };
  const onTestButtonClick = () => {
    let url = document.getElementById("thebox").value;
    if (!url) {
      alert("URL value cannot be blank");
    } else {
      //URL Validity Code
      localStorage.setItem("URL", url);
      if (firstOption !== "http://" || url.startsWith("https://")) {
        window.location.replace(
          `${process.env.REACT_APP_httpsSiteUrl}/test?url=${encodeURIComponent(
            url
          )}`
        );
      } else {
        window.location.replace(`/test?url=${url}`)
      }
    }
  };

  useEffect(() => {
    if (window.navigator.userAgent.includes("Edge")) {
      document
        .getElementsByClassName("dropdown-wrapper")[0]
        .classList.add("hide-dropdown");
    }
    const input = document.getElementById("thebox");

    if (searchParams) {
      input.value = searchParams;
    }

    input.addEventListener("keyup", function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("myBtn").click();
      }
    });
  }, [searchParams]);
  useEffect(() => {
    if (searchParams) {
      const input = document.getElementById("thebox");
      if (searchParams) {
        input.value = searchParams;
      }
    }
  }, [searchParams]);
  return (
    <div className="row">
      <div className="flex-container">
        <div className="dropdown-wrapper">
          <div className="dropdown-inner">
            <div className="dropdown-item" onClick={firstProtocol}>
              <span className="spanclass">{firstOption}</span>
            </div>
            <div className="dropdown-item" onClick={secondProtocol}>
              <span className="spanclass">{secondOption}</span>
            </div>
          </div>
        </div>
        <div className="group">
          <input
            id="thebox"
            autoFocus
            placeholder="example.com"
            spellCheck="false"
            name="url"
            required
            type="text"
            className="validate"
          />
          <span className="highlight"></span>
          <span className="bar"></span>
        </div>
        <a className="container-pulse" id="myBtn" onClick={onTestButtonClick}>
          <span className="pulse-button">Test</span>
        </a>
      </div>
    </div>
  );
};

export default InputUrl;

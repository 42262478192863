import React, { useEffect, useState } from "react";
import "./ShowResult.css";

const ShowResult = ({
  ResultsArrived,
  emitIsRedirection,
  emitRedirectedUrl,
  emiturl,
  headers,
  IPAddress,
  time,
  crossCommunication,
  setisvulnerableornotFn
}) => {
  const [notFirefox, setnotFirefox] = useState(true);
  const [resultsArrived, setResultsArrived] = useState(true);
  const [isRedirected, setisRedirected] = useState(false);
  const [showToolTipText, setshowToolTipText] = useState(false);
  const [site, setsite] = useState("");
  const [resultsArrivedWhenNull, setresultsArrivedWhenNull] = useState();
  const [CSP, setCSP] = useState(null);
  const [XFOH, setXFOH] = useState(null);
  const [xframeoptionsheader, setxframeoptionsheader] = useState(false);
  const [contentsecuritypolicyheader, setcontentsecuritypolicyheader] =
    useState(false);
  const isChecked = () => {
    const r = document.getElementById("switchid");
    crossCommunication(r.checked);
  };
  useEffect(()=>{
    setResultsArrived(ResultsArrived)
  },[ResultsArrived])
  useEffect(() => {
    if (window.navigator.userAgent.includes("Firefox")) {
      setnotFirefox(false);
    }
  }, []);
  useEffect(() => {
    if (emiturl) {
      if (Number(emitIsRedirection) > 0) {
        setisRedirected(true);
        const siteTemp =
          '<a style="color:#039be5; font-weight:400" href="' +
          emitRedirectedUrl +
          '" target="' +
          '_blank">' +
          emitRedirectedUrl +
          "</a>";
        setsite(siteTemp);
        setshowToolTipText(true);
      } else {
        const site3 = JSON.parse(emiturl)["url"];
        const site4 =
          '<a style="color:#039be5; font-weight:400" href="' +
          site3 +
          '" target="' +
          '_blank">' +
          site3 +
          "</a>";
        setsite(site4);
        setResultsArrived(true);
        setresultsArrivedWhenNull(true)
      }
    }
  }, [emitIsRedirection, emiturl, emitRedirectedUrl]);
  useEffect(() => {
    if (headers) {
      const data = headers;
      let FAChecked = false;
      if (data == null) {
        setresultsArrivedWhenNull(true);
      } else {
        setresultsArrivedWhenNull(false);
        let myHeaders = data;

        if (myHeaders["content-security-policy"]) {
          if (
            JSON.stringify(myHeaders["content-security-policy"]).includes(
              "frame-ancestors"
            )
          ) {
            FAChecked = true;
          } else {
            FAChecked = false;
          }
          const extracted = JSON.stringify(
            myHeaders["content-security-policy"]
          ).split(";");
          for (var i = 0; i < extracted.length; i++) {
            if (extracted[i].includes("frame-ancestors")) {
              if (extracted[i].endsWith('"')) {
                extracted[i] = extracted[i].replace('"', "");
                setCSP(extracted[i]);
              } else {
                setCSP(extracted[i]);
              }
            }
          }
        } else {
          FAChecked = false;
        }
        if (myHeaders["x-frame-options"] && FAChecked) {
          setXFOH(myHeaders["x-frame-options"]);
          setxframeoptionsheader(true);
          setcontentsecuritypolicyheader(true);
          if (setisvulnerableornotFn) {
            setisvulnerableornotFn(false);
          }
        } else if (myHeaders["x-frame-options"]) {
          if (!FAChecked) {
            setcontentsecuritypolicyheader(false);
            setXFOH(myHeaders["x-frame-options"]);
            setxframeoptionsheader(true);
            if (setisvulnerableornotFn) {
              setisvulnerableornotFn(false);
            }
          }
        } else if (FAChecked) {
          setcontentsecuritypolicyheader(true);
          setxframeoptionsheader(false);
          if (setisvulnerableornotFn) {
            setisvulnerableornotFn(false);
          }
        } else {
          setcontentsecuritypolicyheader(false);
          setxframeoptionsheader(false);
          if (setisvulnerableornotFn) {
            setisvulnerableornotFn(true);
          }
        }
      }
    }
  }, [headers]);
  return (
    <>
      <div>
        <h4 id="results">Test Results:</h4>
        <table>
          <tbody>
            <tr>
              <td>Site:</td>
              {resultsArrived && (
                <td className="tooltip">
                  {showToolTipText && (
                    <span className="tooltiptext">
                      The server redirected to this url.
                    </span>
                  )}
                  <div
                    className="thesite"
                    dangerouslySetInnerHTML={{ __html: site }}
                  ></div>
                  {isRedirected && (
                    <div id="redirectionmsg">(Redirection followed)</div>
                  )}
                </td>
              )}
            </tr>

            <tr>
              <td>IP Address:</td>
              {resultsArrivedWhenNull ? <td>&nbsp;</td> : <td>{IPAddress}</td>}
            </tr>

            <tr>
              <td>Time:</td>
              {resultsArrived && <td>{time}</td>}
            </tr>

            <tr>
              <td>X-Frame-Options:</td>
              {resultsArrived && (
                <td>
                  {xframeoptionsheader ? (
                    <div>
                      <div className="chip">
                        <img
                          src="/images/greencheck.png"
                          alt="Contact Person"
                        />
                        {XFOH}
                      </div>
                    </div>
                  ) : (
                    <>
                      {resultsArrivedWhenNull ? (
                        <div>&nbsp;</div>
                      ) : (
                        <div className="chip">
                          <img
                            src="/images/redcross1.png"
                            alt="Contact Person"
                          />
                          Missing header
                        </div>
                      )}
                    </>
                  )}
                </td>
              )}
            </tr>

            <tr>
              <td>CSP Header (Frame-Ancestors)</td>
              {resultsArrived && (
                <td>
                  {contentsecuritypolicyheader ? (
                    <div>
                      <div className="chip">
                        <img
                          src="/images/greencheck.png"
                          alt="Contact Person"
                        />
                        {CSP}
                      </div>
                    </div>
                  ) : (
                    <>
                      {resultsArrivedWhenNull ? (
                        <div>&nbsp;</div>
                      ) : (
                        <div className="chip">
                          <img
                            src="/images/redcross1.png"
                            alt="Contact Person"
                          />
                          Missing anti-framing policy
                        </div>
                      )}
                    </>
                  )}
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>

      <br />
      {notFirefox && (
        <div>
          <span
          className="toggle-font"
            style={{
              marginRight: "5px",
              fontWeight: "700",
              color: "#F7567C",
            }}
          >
            Toggle this to show/hide object
          </span>
          <label className="switch">
            <input type="checkbox" id="switchid" onClick={isChecked} />
            <span className="slider round"></span>
          </label>
          <span
          className="toggle-font"
            style={{
              marginLeft: "5px",
              fontWeight: "700",
              color: "#F7567C",
            }}
          >
            on Iframe to Capture PoC
          </span>
        </div>
      )}
      <br />
    </>
  );
};

export default ShowResult;
